












import { Component, Prop, Vue } from 'vue-property-decorator'
import { ElitepageStore } from '@elitepage/store/elitepage'
import { fetchSoundKits } from '@elitepage/api/sound-kits'
import PSoundkitBox from '@elitepage/components/PSoundkitBox.vue'

@Component({ components: { PSoundkitBox } })
export default class SoundKits extends Vue {
    @Prop({ type: String }) producerSlug!: IProducer['slug']

    @ElitepageStore.State('elitepage') elitepage: IElitepage
    @ElitepageStore.State('producer') producer: IProducer

    soundkits: ISoundKit[] = []

    async mounted() {
        this.soundkits = await fetchSoundKits(this.producer.id)
    }
}
